// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'label-c',
  props: {
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    text: { type: String, default: null }
  }
})
