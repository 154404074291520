// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import JQuery from 'jquery'

export default (confirmationType: confirmationTypes, affirmativeFunction: Function) => {
  JQuery('#confirmation').data('bs-confirmation-type', confirmationType)
  JQuery('#confirmation').data('bs-affirmative-function', affirmativeFunction)
  JQuery('#confirmation').modal()
}
