<!-- (c) Cincom Systems, Inc. <2018> - <2022> -->
<!-- ALL RIGHTS RESERVED                      -->
<template>
  <div class="grid-table">
    <div v-if="title || hasHeaderContent || hasTableActions || isSearchable" class="table-header" :class="{'end': !hasHeaderContent && !title}">
      <div class="table-title">
        <i v-if="iconClass" class="icon" :class="iconClass"></i>
        <div v-if="title" class="title">{{ title }}</div>
        <slot v-if="hasHeaderContent" name="table-header-content"></slot>
      </div>
      <div v-if="hasTableActions || isSearchable || isItemAddable" class="table-actions">
        <search-input v-if="isSearchable" :text="pageInfo.search" @search="searchItems($event)" @close="searchItems(null)"/>
        <div v-if="hasTableActions">
          <button type="button" id="table-actions-menu-button" data-sel="actions-menu-button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="!isEnabled">
            <i class="fas fa-table"></i><span class="actions-menu-text">{{ $t('action-dropdown_title') }}</span><i class="fas fa-chevron-down"></i>
          </button>
          <div class="dropdown-menu border-gray" aria-labelledby="table-actions-menu-button">
            <div class="dropdown-item-text">{{ $t('action-dropdown_selected-items') }}<badge :type="$options.BadgeType.Info" :text="selectedItems.length.toString()"/></div>
            <slot name="table-actions"></slot>
          </div>
        </div>
        <button v-if="isItemAddable" class="btn" :disabled="!isEnabled" @click="$emit('add-item')">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>
    <div v-if="items.length === 0 && (Boolean(pageInfo.search) || hasNoDataNotification)" class="no-data">
      <i class="fal fa-file-alt"></i>
      <div>{{ Boolean(pageInfo.search) ? $t('no-records-found_label') : $t('no-data-available_label') }}</div>
    </div>
    <div v-else class="data">
      <div ref="gridTable" class="table-table">
        <!-- table header -->
        <div class="header-row">
          <div v-if="isItemSelectable || isItemSingleSelectable" class="column column-header column-selector">
            <checkbox v-if="isItemSelectable" :value="isAllItemsSelected" @input="toggleAllItemsSelector($event)"/>
          </div>
          <div v-for="column in columns" :key="column.id" class="column column-header"
            :class="{
              'column-boolean': column.dataType === 'Boolean',
              'column-date': column.dataType === 'Date',
              'column-numeric': column.dataType === 'Numeric' || column.dataType === 'Currency',
              'column-list': column.dataType === 'List',
              'column-string': column.dataType === 'String',
              'column-editable': column.isEditable,
              'column-sortable': (isSortable && column.isSortable !== false) || column.isSortable
            }"
            :style="{ width: column.width }"
            :data-name="column.name ? column.name.toLowerCase() : null"
            tabindex="0"
            @keyup.enter="sortItems(column)"
            @click="sortItems(column)"
          >
            <div class="header-cell">
              <div class="header-text">
                <span v-if="(column.dataType === 'Numeric' || column.dataType === 'Currency') && (column.isRequired || isColumnRequired(column))" class="is-required">*</span>
                {{ column.label }}
                <span v-if="column.dataType !== 'Numeric' && column.dataType !== 'Currency' && (column.isRequired || isColumnRequired(column))" class="is-required">*</span>
              </div>
              <div v-if="(isSortable && column.isSortable !== false) || column.isSortable" class="sort-icon">
                <template v-if="column.id === sortColumn.id"> <!-- active sort column -->
                  <i v-if="sortColumn.order === 'ASC'" class="fal fa-sort-amount-up" aria-hidden="true"></i>
                  <i v-else class="fal fa-sort-amount-down" aria-hidden="true"></i>
                </template>
                <i v-else class="fal fa-sort-alt" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div v-if="hasItemCustomActions" class="column column-header column-actions column-sticky"></div>
          <div v-else-if="isItemDeletable" class="column column-header column-delete column-sticky"></div>
        </div>
        <!-- table body / rows -->
        <div v-for="(item, index) in items" :key="index" class="table-row"
          :class="{
            'row-selector': isItemSelectable || isItemSingleSelectable,
            'row-selected': (isItemSelectable || isItemSingleSelectable) && isItemSelected(item),
            'row-severe-warning': (item.isValid === false || item.isComplete === false)
          }"
        >
          <div v-if="isItemSelectable || isItemSingleSelectable" class="column column-selector" :class="{ 'is-change-processing': isChangeProcessing }">
            <checkbox v-if="isItemSelectable" :value="isItemSelected(item)" @input="toggleItemSelector(item, $event)"/>
            <radio-button v-else
              :id="String(id+item.id)"
              :checked="isItemSelected(item)"
              :invalid="!item.isValid" 
              :name="String(id+item.id)"
              :value="item.id" 
              @change="$emit('select-item', item)"
              @keypress.space.enter.prevent="$emit('select-item', item)"
            />
          </div>
          <div v-for="column in columns" :key="column.id" class="column"
            :class="{
              'column-boolean': column.dataType === 'Boolean',
              'column-date': column.dataType === 'Date',
              'column-numeric': column.dataType === 'Numeric' || column.dataType === 'Currency',
              'column-list': column.dataType === 'List',
              'column-string': column.dataType === 'String',
              'column-editable': column.isEditable
            }"
            :style="{ width: column.width }"
            :data-name="column.name ? column.name.toLowerCase() : null"
            tabindex="0"
          >
            <!-- <configurator-control v-if="column.isEditable"
              context="Grid"
              :control="getControl(item, column)"
              :isEnabled="isEnabled"
              @change="$emit('change', { ...$event, itemId: item.id, configurationId: item.configurationId, rootConfigurationId: item.rootConfigurationId })"
            /> -->
            <slot v-if="column.customRender" :name="column.customRender" :item="item"/>
            <image-c v-else-if="column.dataType === 'Image'" :imageUrl="item[column.id] ? item[column.id].uri : null"></image-c>
            <a v-else-if="column.isHyperlink" tabindex="0" href="javascript:" :class="{ 'hyperlink-disabled': !isHyperlinkEnabled}" @click.stop="$emit('hyperlink-item', item)">{{ getColumnText(column.dataType, item[column.id]) }}</a>
            <span v-else>{{ getColumnText(column.dataType, item[column.id]) }}</span>
          </div>
          <div v-if="hasItemCustomActions" class="column column-actions column-sticky">
            <slot name="item-custom-action" :item="item"/>
          </div>
          <div v-else-if="isItemDeletable" class="column column-delete column-sticky">
            <button v-if="isItemDeleteVisible(item)" type="button" class="btn" :disabled="!isEnabled || !isItemDeleteEnabled(item)" tabindex="0" @click="$emit('delete-item', item)">
              <i class="fas fa-trash" :title="!isItemDeleteEnabled(item) ? 'Record cannot be deleted.' : 'Delete'"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <pager :isItemSelectable="isItemSelectable" :pageInfo="pageInfo" :selectedItemCount="selectedItemIds.length" @change-page-index="changePageIndex($event)" @change-page-size="changePageSize($event)"/>
  </div>
</template>
<script src="./grid-table.ts"></script>
<style src="./grid-table.scss" lang="scss"></style>
