// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import LabelC from '@/components/controls/label-c/index.vue'
import { Nullable } from '@/_typings/types/nullable'

export default defineComponent({
  name: 'text-input',
  components: {
    LabelC
  },
  props: {
    countryCode : { type: String, default: 'us' },
    disabled: { type: Boolean, default: false },
    errorText: { type: String, default: null },
    incomplete: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    type: { type: String, default: null },
    validatorKey: { type: Number, default: 0 },
    value: { type: String, default: null }
  },
  emits: [ 'input', 'change' ],
  data() {
    return {
      requireValidator: true,
      valueValidator: true
    }
  },
  methods: {
    changeValue(e: any) {
      this.$emit('change', e.target.value)
      setTimeout(() => {
        this.valueValidator = this.validateValue(e.target.value)
      }, 0)
    },
    inputValue(e: any) {
      this.$emit('input', e.target.value)
      this.requireValidator = true
      this.valueValidator = true
    },
    validateValue(value: Nullable<string>): boolean {
      if (this.type === 'phone' && value && value.startsWith('+')) {
        const { phone } = require('phone')
        return phone(value, { validateMobilePrefix: false, strictDetection: true }).isValid
      } else if (this.type === 'postal-code' && value) {
        const postalCodes = require('postal-codes-js')
        return postalCodes.validate(this.countryCode, value) === true // Returns 'true' if valid, error message as string otherwise.
      }
      let regEx: Nullable<RegExp> =
        this.type === 'email' ? new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$") :
        this.type === 'phone' ? new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) :
        this.type === 'url' ? new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) :
        null
      return regEx && value ? regEx.test(value) : true
    }
  },
  watch: {
    validatorKey(value) {
      this.requireValidator = value === 0 ? true : !this.required || Boolean(this.value)
      this.valueValidator = value === 0 ? true : this.validateValue(this.value)
    }
  }
})
