// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { IPageInfo } from '@/_typings/interfaces/paging'
import { Nullable } from '@/_typings/types/nullable'

export default defineComponent({
  name: 'pager',
  props: {
    isItemSelectable: { type: Boolean, default: false },
    pageInfo: { type: Object as PropType<IPageInfo>, default: () => { return {} } },
    selectedItemCount: { type: Number, default: 0 }
  },
  data() {
    return {
      pageSize: null as Nullable<number>
    }
  },
  mounted() {
    this.pageSize = this.pageInfo.pageSize
  },
  computed: {
    firstRowIndex(): number {
      return this.pageInfo.totalItems ? ((Number(this.pageInfo.pageNumber) - 1) * Number(this.pageSize)) + 1 : 0
    },
    lastRowIndex(): number {
      return this.pageInfo.pageNumber >= this.pageInfo.totalPages ? Number(this.pageInfo.totalItems) : (Number(this.firstRowIndex) + Number(this.pageSize)) - 1
    },
    pageNumberRange() {
      const leftRange: number = Math.floor((this.pageInfo.pageNumber - 1) / 10) * 10 + 1
      const rightRange: number = Math.floor((leftRange + 9) > this.pageInfo.totalPages ? this.pageInfo.totalPages : (leftRange + 9))
      let pageNumbers: number[] = []
      for (let i: number = leftRange; i <= rightRange; i++) {
        pageNumbers.push(i)
      }
      return pageNumbers
    },
    pageSizes(): number[] {
      return [ 10, 25, 50, 100, 250 ]
    }
  },
  methods: {
    changePageIndex(pageIndex: number) {
      if (pageIndex < 1 || pageIndex > this.pageInfo.totalPages) return
      this.$emit('change-page-index', pageIndex)
    }
  },
  watch: {
    pageInfo(newValue: IPageInfo) {
      this.pageSize = newValue.pageSize
    }
  }
})
