// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
export enum BadgeType {
  Default = 'default',
  Info = 'info',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  BannerBlue = 'banner-blue',
  BlueyPurple = 'bluey-purple'
}
