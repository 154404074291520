// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'image-c',
  props: {
    imageUrl: { type: String, default: null }
  }
})
