import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-label", "disabled", "checked", "name", "value"]
const _hoisted_2 = ["for", "tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["radio-button", { 'checked': _ctx.checked, 'invalid': _ctx.invalid, 'disabled': _ctx.disabled, 'is-change-processing': _ctx.isChangeProcessing }]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("input", {
      type: "radio",
      class: "radio-button-input",
      tabindex: "-1",
      id: _ctx.id,
      "aria-label": _ctx.label,
      disabled: _ctx.disabled,
      checked: _ctx.checked,
      name: _ctx.name,
      value: _ctx.value,
      onChange: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('change', _ctx.value)), ["prevent","stop"]))
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.id,
      class: _normalizeClass(["radio-button-label", { 'no-text': !_ctx.label }]),
      tabindex: _ctx.disabled ? -1 : 0
    }, _toDisplayString(_ctx.label), 11, _hoisted_2)
  ], 2))
}