// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import { IDropdownItem } from '@/_typings/interfaces/dropdown-item'
import { Nullable } from '@/_typings/types/nullable'
import Checkbox from '@/components/controls/checkbox/index.vue'
import LabelC from '@/components/controls/label-c/index.vue'
import SearchInput from '@/components/controls/search-input/index.vue'

export default defineComponent({
  name: 'drop-down',
  components: {
    Checkbox,
    LabelC,
    SearchInput
  },
  props: {
    disabled: { type: Boolean, default: false },
    includeNoneListItem: { type: Boolean, default: true },
    incomplete: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    invalidItemIds: { type: Array as PropType<string[]>, default: () => { return [] }},
    isMultiSelect: { type: Boolean, default: false },
    isSearchable: { type: Boolean, default: false },
    items: { type: Array as PropType<IDropdownItem[]>, default: () => { return [] }},
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    selectedItems: { type: Array as PropType<any[]>, default: () => [] },
    validatorKey: { type: Number, default: 0 }
  },
  emits: [ 'change-selected-items', 'select-item' ],
  data() {
    return {
      filterText: null as Nullable<string>,
      requireValidator: true
    }
  },
  computed: {
    ...mapGetters({
      isChangeProcessing: 'getIsChangeProcessing'
    }),
    displayText() : Nullable<string> {
      return this.selectedItemIds.length > 0
        ? this.items.filter(item => this.selectedItemIds.includes(item.id)).map(item => item.name).join(', ')
        : this.readonly ? null : this.placeholder
    },
    filteredItems(): IDropdownItem[] {
      return this.filterText
        ? this.items.filter(item => (item.name).toLowerCase().includes((this.filterText || '').toLowerCase()))
        : this.items
    },
    selectedItemIds() : string[] {
      return this.selectedItems.map(selectedItem => selectedItem.id)
    }
  },
  methods: {
    isItemInvalid(item: IDropdownItem): boolean {
      return this.invalidItemIds.includes(item.id)
    },
    isItemSelected(item: IDropdownItem): boolean {
      return item.id === null && this.selectedItemIds.length === 0 ? true : this.selectedItemIds.includes(item.id)
    },
    selectItem(item: IDropdownItem): void {
      let selectedItems : any[] = []
      if (this.isMultiSelect) {
        if (this.isItemSelected(item)) {
          selectedItems = this.selectedItems.filter(itemId => itemId !== item.id)
        } else {
          selectedItems = [...this.selectedItems, ...[ item ]]
        }
      } else {
        selectedItems = [ item ]
        this.$el.getElementsByClassName('dropdown-menu')[0].classList.remove('show')
      }
      this.$emit('change-selected-items', selectedItems)
      this.$emit('select-item', item)
      this.requireValidator = true
    },
    selectNoneListItem(): void {
      this.$emit('change-selected-items', [])
      this.$emit('select-item', null)
    }
  },
  watch: {
    selectedItems(value) {
      if (value.length > 0) this.requireValidator = true
    },
    validatorKey(value) {
      this.requireValidator = value === 0 ? true : !this.required || this.selectedItemIds.length > 0
    }
  }
})
