// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED     
import { defineComponent } from 'vue'
import { Nullable } from '@/_typings/types/nullable'

export default defineComponent({
  name: 'search-input',
  props: {
    text: { type: String, default: null }
  },
  data() {
    return {
      searchText: null as Nullable<string>,
      searchTextValue: null as Nullable<string>
    }
  },
  created() {
    this.searchTextValue = this.text
    this.searchText = this.text
  },
  methods: {
    close() {
      this.searchTextValue = null
      this.searchText = this.searchTextValue
      this.$emit('close')
    },
    search() {
      this.searchText = this.searchTextValue
      this.$emit('search', this.searchText)
    }
  }
})
