import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["badge", [ _ctx.badgeInfo.type || _ctx.type, { 'wide' : _ctx.badgeInfo.iconClass || _ctx.iconClass } ]]),
    "data-sel": "badge"
  }, [
    (_ctx.badgeInfo.iconClass || _ctx.iconClass)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(["icon", _ctx.badgeInfo.iconClass || _ctx.iconClass])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 2))
}