<!-- (c) Cincom Systems, Inc. <2018> - <2022> -->
<!-- ALL RIGHTS RESERVED                      -->
<template>
  <div class="checkbox" :class="{ 'checked': displayValue, 'invalid': invalid, 'disabled': disabled, 'is-change-processing': isChangeProcessing }">
    <input type="checkbox" :id="id" class="checkbox-input" tabindex="-1" :value="displayValue" @input="changeValue()">
    <label :for="id" role="checkbox" class="checkbox-label" :aria-label="label" :aria-checked="displayValue" :tabindex="disabled ? -1 : 0" @keydown.space.enter.prevent="changeValue()">
      <span v-if="label" class="label text-secondary">{{ label }}<slot/></span>
    </label>
  </div>
</template>
<script src="./checkbox.ts"></script>
<style src="./checkbox.scss" lang="scss"></style>
