// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { BadgeType } from '@/_typings/enums/badgeType'
import { IBadgeInfo } from '@/_typings/interfaces/badge-info'

export function getIsActiveBadgeInfo(isActive: boolean): IBadgeInfo {
  return isActive
    ? { type: BadgeType.Success, iconClass: 'fas fa-caret-circle-right' }
    : { type: BadgeType.Default, iconClass: 'fas fa-stop-circle' }
}

export function getOpportunityStatusBadgeInfo(status: string): IBadgeInfo {
  switch (status) {
    case 'New':
      return { type: BadgeType.BannerBlue, iconClass: 'fas fa-star' }
    case 'Active':
      return { type: BadgeType.Success, iconClass: 'fas fa-caret-circle-right' }
    case 'Won':
      return { type: BadgeType.BlueyPurple, iconClass: 'far fa-award' }
    case 'Lost':
      return { type: BadgeType.Default, iconClass: 'fas fa-island-tropical' }
    default:
      return { type: null, iconClass: null }
  }
}

export function getQuoteStatusBadgeInfo(status: string): IBadgeInfo {
  switch (status) {
    case 'New':
      return { type: BadgeType.BannerBlue, iconClass: 'fas fa-star' }
    case 'Active':
      return { type: BadgeType.Success, iconClass: 'fas fa-caret-circle-right' }
    case 'Revised':
      return { type: BadgeType.Warning, iconClass: 'fas fa-badge-check' }
    case 'Ordered':
      return { type: BadgeType.BlueyPurple, iconClass: 'fas fa-shopping-cart' }
    case 'Closed':
      return { type: BadgeType.Default, iconClass: 'fas fa-door-closed' }
    default:
      return { type: null, iconClass: null }
  }
}

export function getSalesOrderStatusBadgeInfo(status: string): IBadgeInfo {
  switch (status) {
    case 'Active':
      return { type: BadgeType.Success, iconClass: 'fas fa-caret-circle-right' }
    case 'Cancelled':
      return { type: BadgeType.Default, iconClass: 'fas fa-door-closed' }
    case 'Ordered':
      return { type: BadgeType.BlueyPurple, iconClass: 'fas fa-shopping-cart' }
    case 'Closed':
      return { type: BadgeType.Default, iconClass: 'fas fa-door-closed' }
    default:
      return { type: null, iconClass: null }
  }
}
