// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { IBadgeInfo } from '@/_typings/interfaces/badge-info'
import { BadgeType } from '@/_typings/enums/badgeType'

export default defineComponent({
  name: 'badge',
  props: {
    badgeInfo: { type: Object as PropType<IBadgeInfo>, default: () => { return {} } },
    iconClass: { type: String, default: null },
    text: { type: String, default: null },
    type: { type: String as PropType<BadgeType>, default: BadgeType.Default, validator (val: BadgeType) { return Object.values(BadgeType).includes(val) } }
  },
  badgeType: BadgeType
})