import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "search-input",
  "data-sel": "search-input"
}
const _hoisted_2 = ["placeholder", "aria-label"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "form-control mr-sm-2",
      placeholder: _ctx.$t('search_label'),
      "aria-label": _ctx.$t('search_label'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTextValue) = $event)),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.search()), ["enter"]))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.searchTextValue]
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "search-button",
      disabled: !Boolean(_ctx.searchTextValue)
    }, [
      (Boolean(_ctx.searchText) && _ctx.searchText === _ctx.searchTextValue)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "fas fa-times clear-icon",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close()))
          }))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: "fas fa-search search-icon",
            "data-sel": "search-icon",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.search()))
          }))
    ], 8, _hoisted_3)
  ]))
}