// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'radio-button',
  props: {
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    id: { type: String, default: null },
    invalid: { type: Boolean, default: false },
    label: { type: String, default: null },
    name: { type: String, default: null },
    value: { type: String, default: null }
  },
  emits: [ 'change' ],
  computed: {
    ...mapGetters({
      isChangeProcessing: 'getIsChangeProcessing'
    })
  }
})