// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'checkbox',
  props: {
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    label: { type: String, default: null },
    value: { type: Boolean, default: false }
  },
  emits: [ 'input' ],
  data() {
    return {
      displayValue: false
    }
  },
  created() {
    this.displayValue = this.value
  },
  computed: {
    ...mapGetters({
      isChangeProcessing: 'getIsChangeProcessing'
    }),
    id() {
      return (this as any)._.uid
    }
  },
  methods: {
    changeValue() {
      const newValue = !this.value
      this.displayValue = newValue
      this.$emit('input', newValue)
    }
  },
  watch: {
    value() {
      this.displayValue = this.value
    }
  }
})
