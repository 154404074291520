<!-- (c) Cincom Systems, Inc. <2018> - <2022> -->
<!-- ALL RIGHTS RESERVED                      -->
<template>
  <div class="drop-down">
    <label-c v-if="label" :disabled="disabled">{{ label }}</label-c>
    <div class="dropdown" :class="{ 'invalid': !readonly && (invalid || !requireValidator), 'incomplete': incomplete, 'required': required, 'disabled': disabled, 'read-only': readonly }">
      <button type="button" id="dropdown-menu-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn dropdown-toggle border-gray form-control" :tabindex="disabled ? -1 : 0">
        <div class="text">{{ displayText }}</div>
      </button>
      <template v-if="!readonly">
        <span v-if="!requireValidator" class="error-message">{{ $t('required-value_message') }}</span>
      </template>
      <div class="dropdown-menu border-gray" aria-labelledby="dropdown-menu-button">
        <div v-if="isSearchable" class="dropdown-item-search" @click.stop>
          <search-input :text="filterText" @search="filterText = $event" @close="filterText = null"/>
        </div>
        <div class="dropdown-menu-content" :class="{ 'is-change-processing': isChangeProcessing }">
          <div v-if="Boolean(filterText) && filteredItems.length === 0" class="no-data">{{ $t('no-records-found_label') }}</div>
          <template v-else>
            <template v-if="includeNoneListItem && !isMultiSelect && !filterText">
              <a class="dropdown-item" href="javascript:" :class="{'active': selectedItemIds.length === 0 }" @click="selectNoneListItem()" @keydown.prevent.enter.space="selectNoneListItem()">{{ $t('dropdown_none-list-item_text') }}</a>
            </template>
            <template v-for="item in filteredItems" :key="item.id">
              <a class="dropdown-item" href="javascript:" :class="{ 'active': isItemSelected(item), 'invalid': invalidItemIds.includes(item.id) }" @click.stop="selectItem(item)" @keydown.prevent.enter.space="selectItem(item)">
                <div class="dropdown-item-content">
                  <div class="dropdown-item-text">{{ item.name }}</div>
                  <i v-if="isMultiSelect && isItemSelected(item)" class="fas fa-check"></i>
                  <i v-else-if="isItemInvalid(item)" class="far fa-exclamation-circle"></i>
                </div>
              </a>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./drop-down.ts"></script>
<style src="./drop-down.scss" lang="scss"></style>
