// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent, PropType } from 'vue'
import { mapGetters } from 'vuex'
import { BadgeType } from '@/_typings/enums/badgeType'
import Badge from '@/components/controls/badge/index.vue'
import Checkbox from '@/components/controls/checkbox/index.vue'
import ImageC from '@/components/controls/image-c/index.vue'
import Pager from '@/components/controls/pager/index.vue'
import RadioButton from '@/components/controls/radio-button/index.vue'
import SearchInput from '@/components/controls/search-input/index.vue'
import Localization from '@/utils/localization'
import { IColumn } from '@/_typings/interfaces/column'
import { IPageInfo } from '@/_typings/interfaces/paging'
import { ISortColumn } from '@/_typings/interfaces/sort-column'

export default defineComponent({
  name: 'grid-table',
  components: {
    Badge,
    Checkbox,
    ImageC,
    Pager,
    RadioButton,
    SearchInput
  },
  BadgeType,
  props: {
    columns: { type: Array as PropType<IColumn[]>, default: () => [] },
    configurableItemIds: { type: Array as PropType<string[]>, default: () => { return [] }},
    defaultSortColumn: { type: Object as PropType<IColumn | ISortColumn>, default: () => { return {} } },
    deleteEnabledItemIds: { type: Array as PropType<string[]>, default: () => { return [] }},
    deleteVisibleItemIds: { type: Array as PropType<string[]>, default: () => { return [] }},
    hasHeaderContent: { type: Boolean, default: false },
    hasItemCustomActions: { type: Boolean, default: false },
    hasNoDataNotification: { type: Boolean, default: false },
    hasTableActions: { type: Boolean, default: false },
    iconClass: { type: String, default: null },
    isEnabled: { type: Boolean, default: true },
    isHyperlinkEnabled: { type: Boolean, default: true },
    isItemAddable: { type: Boolean, default: true },
    isItemDeletable: { type: Boolean, default: false },
    isItemSelectable: { type: Boolean, default: false },
    isItemSingleSelectable: { type: Boolean, default: false },
    isSearchable: { type: Boolean, default: false },
    isSortable: { type: Boolean, default: false },
    items: { type: Array as PropType<any[]>, default: () => [] },
    pageInfo: { type: Object as PropType<IPageInfo>, default: () => { return {} } },
    selectedItems: { type: Array as PropType<any[]>, default: () => [] },
    title: { type: String, default: null }
  },
  emits: [ 'add-item', 'change', 'change-paged-items', 'change-selected-items', 'configure-item', 'delete-item', 'hyperlink-item', 'select-item' ],
  data() {
    return {
      sortColumn: this.defaultSortColumn as IColumn | ISortColumn
    }
  },
  computed: {
    ...mapGetters({
      isChangeProcessing: 'getIsChangeProcessing'
    }),
    id(): string {
      return (this as any).$.uid.toString()
    },
    isAllItemsSelected() : boolean {
      if (this.items.length === 0) return false
      const itemIds: string[] = this.items.map(item => item.id)
      return itemIds.every(id => this.selectedItemIds.includes(id))
    },
    isColumnRequired() : Function {
      return (column) => {
        return this.items.some(item => {
          return (item as any).controls && (item as any).controls.some(control => {
            return control.id === column.id && control.isEnabled && control.isRequired && !control.isComplete
          })
        })
      }
    },
    selectedItemIds() : string[] {
      return this.selectedItems.map(selectedItem => selectedItem.id)
    }
  },
  methods: {
    changePageIndex(pageIndex: number) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: pageIndex })
    },
    changePageSize(pageSize: number) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, pageSize: pageSize })
    },
    getColumnText(dataType: string, value: any) {
      switch (dataType) {
        case 'Array':
        case 'List':
          return value && value.length > 0 ? value.map(item => item.value).join(', ') : null
        case 'Boolean':
          return value && (value === 'true' || value === true) ? 'Yes' : 'No'
        case 'Currency':
          return value !== null ? Localization.formatCurrency(value) : null
        case 'Date':
          return value !== null ? Localization.formatDateUTC(value) : null
        case 'Numeric':
          return value !== null ? Localization.formatNumber(value) : null
        default:
          return value
      }
    },
    // getControl(item: any, column: any) {
    //   if (!column.isEditable || !item.controls) return {}
    //   const control = item.controls.find(control => control.id === column.id)
    //   if (control === undefined) return {}
    //   if (control.dataType === 'List') control.options = column.options[control.lookupId]
    //   return control
    // },
    // isItemConfigureVisible(item: any) : boolean {
    //   return this.configurableItemIds.includes(item.id)
    // },
    // isItemDeleteEnabled(item: any) : boolean {
    //   return this.deleteEnabledItemIds.includes(item.id)
    // },
    // isItemDeleteVisible(item: any) : boolean {
    //   return this.deleteVisibleItemIds.includes(item.id)
    // },
    isItemSelected(item: any) : boolean {
      return (this.selectedItemIds.length === 0 && item.id === null) || this.selectedItemIds.includes(item.id)
    },
    searchItems(searchText: string) {
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, search: searchText })
    },
    selectItem(item: any) {
      this.$emit('change-selected-items', [...this.selectedItems, ...[item]])
    },
    selectItems() {
      this.$emit('change-selected-items', [...this.selectedItems, ...this.items.filter(item => !this.selectedItems.some(selectedItem => selectedItem.id === item.id))])
    },
    sortItems(column: IColumn) {
      if (!this.isSortable && !column.isSortable) return
      this.sortColumn = { 
        id: column.id,
        name: column.name,
        dataType: column.dataType,
        order: column.id === this.sortColumn.id && this.sortColumn.order === 'ASC' ? 'DESC' : 'ASC'
      }
      this.$emit('change-paged-items', { ...this.pageInfo, pageNumber: 1, sortedBy: this.sortColumn.name, sortDirection: this.sortColumn.order === 'DESC' ? 'Descending' : 'Ascending' })
    },
    toggleAllItemsSelector(value: boolean) {
      if (value) this.selectItems(); else this.unselectItems()
    },
    toggleItemSelector(item: any, value: boolean) {
      if (value) this.selectItem(item); else this.unselectItem(item)
    },
    unselectItem(item: any) {
      this.$emit('change-selected-items', this.selectedItems.filter(selectedItem => selectedItem.id !== item.id))
    },
    unselectItems() {
      this.$emit('change-selected-items', [])
    }
  }
})
